import { Controller } from "@hotwired/stimulus"
import { jsRequestHeaders, trackAction } from '@javascript/javascripts/utils'
import { tns } from 'tiny-slider/src/tiny-slider.js'

export default class extends Controller {

  static get targets() {
    return ['carousel', 'carouselPaginationNext', 'carouselPaginationPrev', 'carouselProgressBar', 'carouselProgressSteps', 'carouselLoader', 'surveyTitle', 'stepChapter', 'surveyCategoryTitle']
  }

  initialize() {
    this.quizzPaginationDelay = 2000;
    this.pollPaginationDelay = 500;
    this.canGoNext = true;
  }

  connect() {
    this.tracked = {}
    if(!this.element.dataset.disableCarousel){
      this._setupCarousel();
    } else {
      this.carouselTarget.classList.remove('max-h-screen')
      this.carouselTarget.classList.remove('overflow-hidden')
      Array.from(this.carouselTarget.childNodes).map(c => c.classList.add('p-16'))
    }
    this._displayLeaveWarningMessage()
  }

  _displayLeaveWarningMessage(){
    const text = this.data.get('abandonMessage')
    const all = [...document.querySelectorAll('a')]
    const exceptions = [...this.element.querySelectorAll('a')]
    const exiters = all.filter(a => exceptions.indexOf(a) === -1)
    const preventExit = (e) => {
      if(!confirm(text)){ e.preventDefault() }
    }
    exiters.map(a => a.addEventListener('click', preventExit))
  }

  carouselSlideNext(event){
    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
    if (this.canGoNext) {
      this.canGoNext = false;
      const currentStepAttributes = this._getCurrentStepAttributes()
      if(currentStepAttributes.last){
        if (window.self !== window.top) {
          const url = this.data.get('confirmation-url').replace('/surveys/', '/lms/surveys/')
          document.location.href = url + '?jwt=' + localStorage.getItem('jwt')
        } else {
          document.location.href = this.data.get('confirmation-url')
        }     
      } else {
        const lastProgressionPosition = document.querySelector('[data-track]').dataset.lastProgressionPosition;
        if (currentStepAttributes.position == lastProgressionPosition) {
          document.querySelector('[data-track]').dataset.lastProgressionPosition = currentStepAttributes.position + 1;
        }
        this.carousel.goTo('next');
      }
    }
  }

  carouselSlidePrev(event){
    event.preventDefault();
    this.carousel.goTo('prev');
  }

  _registerEvents(){
    this.carouselTarget.addEventListener('slide-next', (event) => {
      const stepAttributes = this._getCurrentStepAttributes();  
      if (window.self !== window.top) {
        let quizStats = window.localStorage.getItem('quizStats-' + stepAttributes.survey_id);
        let completedAt = null
        let correctAnswers = 0
        let totalAnswers = 0
        let scorePercent = 0
        let startSessionTime = 0
        let sessTimeSpent = 0

        if (quizStats) {
          quizStats = JSON.parse(quizStats)
          correctAnswers = quizStats.correct
          totalAnswers = quizStats.total
          scorePercent = totalAnswers > 0 ? Math.round((correctAnswers / totalAnswers) * 100) : 0
          startSessionTime = quizStats.startSessionTime
          if (startSessionTime) {
            sessTimeSpent = Math.round((new Date().getTime() - startSessionTime) / 1000)
          }

          let progressionPercent = (100 / (this.carousel.getInfo().slideCount)) * (this.carousel.getInfo().index + 1)

          if(stepAttributes.last){
            completedAt = new Date().toISOString()
            progressionPercent = 100
            localStorage.removeItem('quizStats-' + stepAttributes.survey_id)
          }

          const message = {
            progressionPercent,
            scorePercent,
            completedAt,
            sessTimeSpent,
            successStatus: 'unknown'
          }

          if (completedAt == null) {
            window.parent.postMessage(message, '*')
          } else {
            quizStats.sessTimeSpent = sessTimeSpent
            quizStats.progressionPercent = progressionPercent
            quizStats.scorePercent = scorePercent
            quizStats.completedAt = completedAt
            localStorage.setItem('quizStats-' + stepAttributes.survey_id, JSON.stringify(quizStats))
          }
        }
      }

      if (this.canGoNext) {
        this.canGoNext = false;
        const paginationDelay = event.isQuizzMode ? this.quizzPaginationDelay : this.pollPaginationDelay;
        setTimeout(() => {
          if(stepAttributes.last){
            if (window.self !== window.top) {
              const url = this.data.get('confirmation-url').replace('/surveys/', '/lms/surveys/')
              document.location.href = url + '?jwt=' + localStorage.getItem('jwt')
            } else {
              document.location.href = this.data.get('confirmation-url')
            }
          } else {
            const lastProgressionPosition = document.querySelector('[data-track]').dataset.lastProgressionPosition;
            if (stepAttributes.position == lastProgressionPosition) {
              document.querySelector('[data-track]').dataset.lastProgressionPosition = stepAttributes.position + 1
            }
            this.carousel.goTo('next');
          }
        }, paginationDelay + event.messageLength * 20)
      }
    })
    this.carouselTarget.addEventListener('slide-prev', () => { this.carousel.goto('prev') })
  }

  _setupCarousel(){
    this.carousel = tns({
      container: this.carouselTarget,
      controls: false,
      speed: 200,
      touch: false,
      nav: false,
      loop: false,
      items: 1,
      nonce: 'oVyNsBBOgnKX4o3kiJDiAQ=='
    });

    if (window.self !== window.top) {
      const localStorage = window.localStorage;
      const currentStepAttributes = this._getCurrentStepAttributes();
      this.surveyID = currentStepAttributes.survey_id;

      if (!localStorage.getItem('quizStats-' + this.surveyID)) {
        localStorage.setItem('quizStats-' + this.surveyID, JSON.stringify({ correct: 0, total: 0, startSessionTime: new Date().getTime(), status: 'unknown' }));
      }
    }

    this._registerVideoPlayers()
    this._handleBrowserHistoryState()
    this._afterSlideChange();
    this._registerEvents();
    this.carousel.events.on('indexChanged', this._afterSlideChange.bind(this))
  }

  _registerVideoPlayers() {
    document.addEventListener('video.ready', (e) => {
      this.loadedVideoPlayerComponents = this.loadedVideoPlayerComponents || {}
      this.loadedVideoPlayerComponents[e.detail.identifier || e.detail.player.id] = e.detail.player

      const currentPlayer = e.detail.player
      const playerStepId = e.detail.identifier.replace('step_', '')

      const handleVideoEnd = () => {
        if (this._getCurrentStepAttributes().last) {
          if (window.self !== window.top) {
            const url = this.data.get('confirmation-url').replace('/surveys/', '/lms/surveys/')
            document.location.href = url + '?jwt=' + localStorage.getItem('jwt')
          } else {
            document.location.href = this.data.get('confirmation-url')
          }
        } else {
          setTimeout(() => {
            document.querySelector(`[data-step-id="${playerStepId}"] [data-action="app--survey-steps--survey-steps-component#carouselSlideNext"]`).classList.remove('hidden');
          }, 1000)
          this.carouselSlideNext()
        }
      };

      currentPlayer.addEventListener('pause', (e) => {
        for (const trigger of e.triggers?.chain) {
          if (trigger.data) {
            const data = JSON.parse(trigger.data)
            if (data?.data?.percent >= 0.985) {
              handleVideoEnd()
            }
          }
        }
      })
    });
  }

  _afterSlideChange(){
    const element = document.querySelector('[data-track]');
    const dataTrackValue = element.dataset.track;
    this._updateProgressElements()
    this._updatePageUrl()
    if(dataTrackValue === 'true') {
      this._trackStepView();
    }
    this._handleVideoPlayers();
    this._disableLeaveWarningMessage();
    this._toggleNavigation();
    this.canGoNext = true;
  }

  _disableLeaveWarningMessage(){
  }

  _handleVideoPlayers(){
    const stepAttributes = this._getCurrentStepAttributes()
    const currentPlayerIdentifier = `step_${stepAttributes.id}`

    // when navigating through slides, pause all players by default
    if(this.videoPlayer){
      this.videoPlayer.pause()
    }


    // when navigating through slides, activate play only current step player
    if(this.loadedVideoPlayerComponents && this.loadedVideoPlayerComponents[currentPlayerIdentifier]){
      this.videoPlayer = this.loadedVideoPlayerComponents[currentPlayerIdentifier]
    }
  }

  _handleBrowserHistoryState(){
    const url = new URL(document.location);
    const params = new URLSearchParams(url.search.slice(1));
    const currentStepAttributes = this._getCurrentStepAttributes();

    if(params.get('step_id') != null || currentStepAttributes.first_step_to_display_id != null){
      const slide = this.carouselTarget.querySelector(`[data-step-id="${currentStepAttributes.first_step_to_display_id || params.get('step_id')}"]`)
      this.carousel.goTo(slide.dataset.carouselSlide)
      this._toggleNavigation();
    }
    window.onpopstate = (event) => {
      if(event.state && parseInt(event.state.slideIndex, 10) > -1){
        this.carousel.goTo(event.state.slideIndex)
      }
    }
  }

  _updatePageUrl(){
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search.slice(1));

    if(this.carousel.getInfo().index === 0){
      params.delete('step_id')
    } else {
      params.set('step_id', this._getCurrentStepAttributes().id)
    }

    if(window.history.state && (window.history.state.slideIndex != this.carousel.getInfo().index)){
      const qs = params.toString()
      const location = window.location.protocol + "//" + window.location.host + window.location.pathname + (qs.length > 0 ? `?${params.toString()}` : '');
      window.history.pushState({ slideIndex: this.carousel.getInfo().index }, '', location);
    }
  }

  _updateProgressElements(){
    if(this.hasStepChapterTarget){
      this.stepChapterTarget.innerHTML = this._getCurrentStepAttributes().chapter
    }

    this.carouselProgressStepsTarget.innerHTML = `${this.carousel.getInfo().index + 1}/${this.carousel.getInfo().slideCount}`
    this.carouselProgressBarTarget.style.width = `${this._computeProgress()}%`
  }

  _focusIfInputIsPresent(){
    const input = this._getCurrentSlide().querySelectorAll('input[type="text"],textarea')[0]
    if(input && screen.width > 1200){ setTimeout(() => input.focus(), 1500) }
  }

  _trackStepView(){
    const currentStepAttributes = this._getCurrentStepAttributes();
    if(this._getCurrentStepAttributes().last){
      const surveyEndTrackingKey = `surveys/end`
      if(!this.tracked[surveyEndTrackingKey]){
        trackAction('stepview', surveyEndTrackingKey, { step_position: currentStepAttributes.position })
        this.tracked[surveyEndTrackingKey] = 1
      }
    }
    const defaultTrackingKey = `/surveys/${currentStepAttributes.survey_id}/steps/${currentStepAttributes.id}`
    if(!this.tracked[defaultTrackingKey]){
      trackAction('stepview', defaultTrackingKey, { step_position: currentStepAttributes.position })
      this.tracked[defaultTrackingKey] = 1
    }
  }

  _fixCarouselCss(){
    const style = this.carouselTarget.getAttribute('style')
    const regexp = /translate3d\((\-)?((\d+)\.\d+%)/
    if(style.match(regexp)){
      this.carouselTarget.setAttribute('style', style.replace(regexp, 'translate3d($1$3%'))
    }
  }

  _computeProgress (){ return (100 / (this.carousel.getInfo().slideCount - 1)) * (this.carousel.getInfo().index) }

  _getCurrentStepAttributes (){ return JSON.parse(this._getCurrentSlide().dataset.stepAttributes) }

  _getCurrentSlide(){ return this.carouselTarget.querySelector('[data-carousel-slide="' + this.carousel.getInfo().index + '"]') }

  _toggleNavigation() {
    const element = document.querySelector('[data-track]');
    const lastProgressionPosition = element.dataset.lastProgressionPosition;
    const currentStepAttributes = this._getCurrentStepAttributes()

    if (currentStepAttributes.position > 1) {
      document.querySelector('button[aria-label="Previous"]:not(.custom-nav)')?.classList.remove('hidden')
    } else {
      document.querySelector('button[aria-label="Previous"]:not(.custom-nav)')?.classList.add('hidden')
    }

    if (currentStepAttributes.position < lastProgressionPosition) {
      document.querySelector('button[aria-label="Next"]:not(.custom-nav)')?.classList.remove('hidden')
    } else {
      document.querySelector('button[aria-label="Next"]:not(.custom-nav)')?.classList.add('hidden')
    }
  }

  _roundCssTransformMatrix(el){
    el.style.transform = "";
    let mx = window.getComputedStyle(el, null);
    if(mx){
      mx = mx.getPropertyValue("-webkit-transform") ||
        mx.getPropertyValue("-moz-transform") ||
        mx.getPropertyValue("-ms-transform") ||
        mx.getPropertyValue("-o-transform") ||
        mx.getPropertyValue("transform") || false;

      let values = mx.replace(/ |\(|\)|matrix/g,"").split(",");
      for(const v in values) { values[v] = v > 4 ? Math.ceil(values[v]) : values[v] }
      el.style.transform = "matrix(" + values.join() + ")"
    }
  }

}

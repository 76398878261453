import { Controller } from "@hotwired/stimulus"
import axios from 'axios'
import confetti from 'canvas-confetti'
import html2pdf from 'html2pdf.js'
import { track } from '@javascript/javascripts/analytics'

export default class extends Controller {

  static get targets() {
  }

  connect(){
  }

  initialize() {
    var count = 200;
    var defaults = {
      origin: { y: 0.7 }
    };

    function fire(particleRatio, opts) {
      confetti(Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio)
      }));
    }

    if (window.location.hostname.startsWith('preview')) {
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });

      document.getElementById("loading-certificate").style.display = "none";
      document.getElementById("certificate-survey-description").style.display = "none";
      document.getElementById("display-preview").style.display = "block";

      return;
    }

    const surveyId = document.querySelector('input[name="surveyId"]').value;
    const participantId = document.querySelector('input[name="participantId"]').value;
    const participantName = document.querySelector('input[name="participantName"]').value;

    return axios({
      method: 'get',
      url: Env.api_url + `/app-ruby/get-participation-certificate?surveyId=${surveyId}&participantId=${participantId}`,
      headers: {'accept': 'application/javascript'}
    }).then(certificate => {
      if (this.element.dataset.isEvaluationSurvey === 'true' && !localStorage.getItem(`reloadAfterGetCertificate-${surveyId}`)) {
        localStorage.setItem(`reloadAfterGetCertificate-${surveyId}`, true)
        window.location.reload()
      }
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });

      const i18nLocale = document.querySelector('input[name="i18nLocale"]').value;
      const issuedOn = new Date(certificate.data.issuedOn);

      document.getElementById("loading-certificate").style.display = "none";
      document.getElementById("display-certificate").style.display = "block";

      if (certificate.data.isCertified) {
        document.getElementById("certificate-of-achievement").style.display = "block";
      } else {
        document.getElementById("certificate-of-completion").style.display = "block";
      }

      document.getElementById("certificate-participant-name").innerHTML = certificate.data.participantName;
      document.getElementById("certificate-issued-on").innerHTML = issuedOn.toLocaleDateString(i18nLocale);
      document.getElementById("certificate-survey-title").innerHTML = certificate.data.surveyTitle[i18nLocale];
      document.getElementById("certificate-survey-description").innerHTML = certificate.data.surveyDescription[i18nLocale] || null;
      document.getElementById("certificate-link").href = certificate.data.certificateLink;
      document.getElementById("certificate-link").innerHTML = certificate.data.certificateLink;
      document.getElementById("certificate").dataset.accountId = certificate.data.accountId;
      const accountLogo = document.getElementById("certificate-account-logo");
      if (accountLogo) {
        accountLogo.src = certificate.data.logoUrl;
      }
      if (certificate.data.userId) {
        document.getElementById("certificate").dataset.userId = certificate.data.userId;
      }
      document.getElementById("linkedin-add-to-profile-link").href = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${certificate.data.surveyTitle[i18nLocale]}&organizationId=55036572&issueYear=${issuedOn.getFullYear()}&issueMonth=${issuedOn.getMonth()+1}&certUrl=${certificate.data.certificateLink}&certId=${certificate.data.certificateId}`

      if (window.top !== window.self) {  
        this.sendQuizStatsMessage(surveyId, 'passed')
      }

    }).catch(error => {
      if (window.top !== window.self) {  
        this.sendQuizStatsMessage(surveyId, 'failed')
      }
      if (error.response.status === 422) {
        document.getElementById("loading-certificate").style.display = "none";
        document.getElementById("failed-certificate").style.display = "block";
      } else {
        throw error;
      }
    });
  }

  updateCertificateName(event) {
    event.preventDefault();

    const surveyId = document.querySelector('input[name="surveyId"]').value;
    const participantId = document.querySelector('input[name="participantId"]').value;
    const participantName = this.escapeHTML(document.querySelector('input[name="participantName"]').value);

    return axios({
      method: 'patch',
      url: Env.api_url + "/app-ruby/update-certificate-name",
      data: {
        surveyId,
        participantId,
        participantName: participantName
      },
      headers: {'accept': 'application/javascript'}
    })
    .then(() => {
      document.getElementById("certificate-participant-name").innerHTML = participantName;
    });
  }

  resetProgression(event) {
    event.preventDefault();

    const surveyId = document.querySelector('input[name="surveyId"]').value;
    const participantId = document.querySelector('input[name="participantId"]').value;

    return axios({
      method: 'post',
      url: Env.api_url + "/app-ruby/reset-progression",
      data: {
        surveyId,
        participantId,
      },
      headers: {'accept': 'application/javascript'}
    })
    .then(() => {
      const currentUrl = window.location.href;
      if (window.self !== window.top) {
        window.location.replace(currentUrl.replace('/confirmation', ''));
        return;
      }
      window.location.replace(currentUrl.substring(0, currentUrl.length - 13));
    });
  }

  async downloadCertificate(event) {
    event.preventDefault();

    const participantId = document.querySelector('input[name="participantId"]').value;
    const accountId = document.getElementById("certificate").dataset.accountId;
    const surveyName = document.getElementById("certificate-survey-title").innerHTML;
    const surveyId = document.querySelector('input[name="surveyId"]').value;
    const userId = document.getElementById("certificate").dataset.userId;

    let trackedEntity = {};
    if (userId) {
      trackedEntity['id'] = userId;
    } else {
      trackedEntity["id"] = "participant-" + participantId;
    }

    track(trackedEntity["id"], accountId, 'Participant downloaded a certificate',
      {
        surveyId,
        surveyName
      }
    );

    const content = document.getElementById("certificate").cloneNode(true);
    const logoElement = content.querySelector("#certificate-account-logo");
    logoElement.src += "?t=" + new Date().getTime();

    fetch(logoElement.src).then(async (response) => {
      const options = {
        margin: 1,
        filename: 'certificate.pdf',
        image: { type: 'png', quality: 1 },
        html2canvas: { scale: 2 }
      };

      const data = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = () => {
        const imageData = reader.result;
        logoElement.src = imageData;

        return html2pdf().set(options).from(content).outputPdf('datauristring').save();
      };
    })
  }

  async reviewSurvey(event) {
    event.preventDefault();
    window.location.href = event.srcElement.dataset.reviewSurveyUrl + '&jwt=' + localStorage.getItem('jwt');
  }

  sendQuizStatsMessage(surveyId, successStatus) {
    let quizStats = window.localStorage.getItem('quizStats-' + surveyId);
    if (quizStats) {
      quizStats = JSON.parse(quizStats);
      let message = {
        progressionPercent: quizStats.progressionPercent,
        scorePercent: quizStats.scorePercent,
        completedAt: quizStats.completedAt,
        sessTimeSpent: quizStats.sessTimeSpent,
        successStatus: successStatus
      };
      window.parent.postMessage(message, '*')
      window.localStorage.removeItem('quizStats-' + surveyId);
    }
  }

  escapeHTML(str) {
    return str.replace(/[&<>"'\/]/g, function (char) {
      switch (char) {
        case '&':
          return '&amp;';
        case '<':
          return '&lt;';
        case '>':
          return '&gt;';
        case '"':
          return '&quot;';
        case '\\':
          return '&#39;';
        case '/':
          return '&#x2F;';
        default:
          return char;
      }
    })
  }
}
